import { AllApprovalStatusSubStates, ApprovalStatus } from '../types';

type Props = {
    isLocked: boolean;
    approval_status?: ApprovalStatus;
    approval_sub_status?: AllApprovalStatusSubStates | null;
};

const getShouldShowExpiringAlert = (props: Props) => {
    if (
        props.isLocked ||
        (props.approval_status?.toUpperCase() === 'APPROVED' &&
            props.approval_sub_status?.toUpperCase() === 'ENROLLED') ||
        ['REJECTED', 'EXPIRED'].includes(props.approval_status?.toUpperCase() || '')
    ) {
        return false;
    }
    return true;
};

export default getShouldShowExpiringAlert;
