import React from 'react';
import { intervalToDuration } from 'date-fns';

interface ExpiringAlertProps {
    expiresAt?: string | null;
    children?: React.ReactNode;
}

const getUtcDate = (date: string) => {
    const dateObj = new Date(date);
    return new Date(
        Date.UTC(
            dateObj.getFullYear(),
            dateObj.getMonth(),
            dateObj.getUTCDate(),
            dateObj.getUTCHours(),
            dateObj.getUTCMinutes(),
            dateObj.getUTCSeconds()
        )
    );
};

const ExpiringAlert: React.FC<ExpiringAlertProps> = ({ expiresAt, children }) => {
    const now = new Date();

    if (!expiresAt) {
        return React.cloneElement(children as React.ReactElement, {
            days: 0,
            hours: 0,
            show: false
        });
    }

    const referenceDate = getUtcDate(expiresAt);

    const { hours, days: daysDiffInterval } = intervalToDuration({
        start: now,
        end: referenceDate
    });

    const daysDiff =
        daysDiffInterval !== undefined && Number.isInteger(daysDiffInterval)
            ? Math.abs(daysDiffInterval)
            : undefined;

    const days = daysDiff ? daysDiff : 0;

    const limit = 10;

    return React.cloneElement(children as React.ReactElement, {
        days,
        hours,
        show: daysDiff && daysDiff <= limit
    });
};

export default ExpiringAlert;
